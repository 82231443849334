// External dependencies
import React, { Fragment } from 'react';

// Import components
import Header from './components/modules/Header';
import Card from './components/modules/Card';
import FullScreen from './components/modules/FullScreen';

// Import assets
import RotationIcon from './assets/icons/Rotation';

// Styles
import './App.scss';

class App extends React.Component {

  state = {
    // Array of cards results
    cards: [
      {
        status: 'new',
        cardId: null
      }
    ]
  }

  componentDidMount = () => {
    window.addEventListener("orientationchange", this.handleOrientation )
  }

  handleOrientation = () => {
      // Android Devices
      if (window.screen.orientation) {

        if ((window.screen.orientation.angle === 90) || (window.screen.orientation.angle === 270)) {
          if (!this.state.isFullScreen) {
            if (this.state.rotationOverlay) {
              this.toggleRotationOverlay();
            }
            this.toggleFullScreen();
          }
        } else {
          if (this.state.isFullScreen) {
            this.toggleFullScreen();
          }
        }
      
      // IOS Devices
      } else {
        if ((window.orientation === 90) || (window.orientation === -90)) {
          if (!this.state.isFullScreen) {
            if (this.state.rotationOverlay) {
              this.toggleRotationOverlay();
            }
            this.toggleFullScreen();
          }
        } else {
          if (this.state.isFullScreen) {
            this.toggleFullScreen();
          }
        }
      }
  }

  // Function to add a completed card to the state array
  // Value "cardId" comes from the Question component
  addNewCard = (cardId) => {

    const isCardInArray = this.state.cards.find((card) => {
      return card.cardId === cardId; 
    });

    // If the card is not yet in the array
    if (!isCardInArray) {
      const updateState = this.state;
  
      updateState.cards.push({
        status: 'answer',
        cardId: cardId
      });
  
      this.setState(updateState);
    }
  }


  removeCard = (cardId) => {
    const cardsArray = this.state.cards;

    for( let i = 0; i < cardsArray.length; i++){ 
      if ( cardsArray[i].cardId === cardId) {
        cardsArray.splice(i, 1);
      }
    }

    this.setState({
      cards: []
    },() => {
      this.setState({
        cards: cardsArray
      })
    })
  }

  cardToggle = (cardId) => {
    const card = document.getElementsByClassName(cardId)[0];
    const button = card.querySelector('.card--answer-header-button');
    const content = card.querySelector('.card--answer-content');

    const isClosed = card.classList.contains("closed");

    button.disabled = true;

    if (!isClosed) {
      content.style.height = content.offsetHeight + 'px';
    }

    card.classList.toggle("closed");
    
    if (!isClosed) {
      content.style.height = 'auto';
    }

    setTimeout(() => {button.disabled = false}, 300);

  };

  toggleFullScreen = () => {
    // If there is at least one card on the list
    if (this.state.cards.length > 1) {
      this.setState((prevState) => ({
        isFullScreen: !prevState.isFullScreen,
      }))
    }
  }

  toggleRotationOverlay = () => {
    this.setState((prevState) => ({
      rotationOverlay: !prevState.rotationOverlay,
    }))
  }

  resetResults = () => {
    this.setState({
      cards: []
    }, () => {

      this.setState({
        cards: [
          {
            status: 'new',
            cardId: null
          }
        ]
      });
    });
  };
  

  render (){

    return (
      <div className='app'>

        {this.state.isFullScreen ? 

          <FullScreen cardsArray={this.state.cards} toggleFullScreen={this.toggleFullScreen} addNewCard={this.addNewCard} />
        
        : 
          <Fragment>

            <Header resetResults={this.resetResults} />
    
            <div className="main">
              {this.state.cards.map((item, i) => (
                <Card 
                  key={i}
                  index={i}
                  status={item.status}
                  cardId={item.cardId}
                  addNewCard={this.addNewCard}
                  removeCard={this.removeCard}
                  cardToggle={this.cardToggle}
                  toggleRotationOverlay={this.toggleRotationOverlay}
                />
              ))}
            </div>
    
            {this.state.rotationOverlay && 
              <div className="rotation-overlay" onClick={this.toggleRotationOverlay}>
                <span className="rotation-overlay-icon"><RotationIcon /></span>
                <p className="rotation-overlay-text">Orientez votre téléphone en mode paysage pour afficher le schéma.</p>
              </div>
            }

          </Fragment>
        }

      </div>
    )
  }
}

export default App;
