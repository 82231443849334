import React from 'react';

import Logo from '../../assets/images/logo-zhc.png';

const Header = (props) => (
    <div className="header">
        <button className="header-logo" onClick={props.resetResults}>
            <img src={Logo} alt="logo" />
        </button>
    </div>
)

export default Header;