// External dependencies
import React, { Fragment } from 'react';
import { isArray } from 'lodash';

// Import questions config
import matchAnswer from "../../config/answers";

// Import components
import Button from './Button';
import CardComment from './Comment' ;

//Import assets
import AerialForbiddenIcon from '../../assets/icons/AerialForbidden';
import NoiseIcon from '../../assets/icons/Noise';
import TrashIcon from '../../assets/icons/Trash';
import EyeIcon from '../../assets/icons/Eye';

function Answer({ cardId, addNewCard, cardToggle, removeCard, toggleRotationOverlay }) {

  const answer = cardId.split('-');

  let card = matchAnswer(answer, true);
  let section = false;
  if (!card) {
    section = answer.pop();
    card = matchAnswer(answer, true);
  }

  function CardZoneDistance({zone}) {
    if (isArray(zone)) {
      return (
        <ul className="list-wind">
          <li>
            <p className="list-wind-title">Au vent:</p>
            <p className="list-wind-text">{zone[0]}m</p>         
          </li>
          <li>
            <p className="list-wind-title">Sous le vent:</p> 
            <p className="list-wind-text">{zone[2]}m</p>         
          </li>
          <li>
            <p className="list-wind-title">Vent de travers:</p>
            <p className="list-wind-text">{zone[1]}m</p>         
          </li>
        </ul>
      );
    }
    return (
      <p className="list-zones-text">{ zone }m</p>
    );
  }

  function CardZone({zones, radiation}) {

    let radiationLevel = {
      zone1: '',
      zone2: '',
      zone3: ''
    };

    if(radiation) {
      if(radiation.zone1) {radiationLevel.zone1 = '(' + radiation.zone1 + ')'};
      if(radiation.zone2) {radiationLevel.zone2 = '(' + radiation.zone2 + ')'};
      if(radiation.zone3) {radiationLevel.zone3 = '(' + radiation.zone3 + ')'};
    }
    
    return (
      <div className="card--answer-content-lists">
        <ul className="list-zones">
          { zones.hbd &&
            <li className="list-zones-item hbd">
              <p className="list-zones-title">HBD:</p>
              <CardZoneDistance zone={zones.hbd } />
            </li>
          }
          <li className="list-zones-item lethal">
          <p className="list-zones-title">Zone 1 : Mortelle {zones.hbd && (zones.hbd === zones.zone1) && '( = HBD ) '} {radiationLevel.zone1}</p>
            <CardZoneDistance zone={zones.zone1 } />
          </li>
          <li className="list-zones-item exclusion">
            <p className="list-zones-title">Zone 2 : Exclusion {radiationLevel.zone2}</p>
            <CardZoneDistance zone={zones.zone2 } />
          </li>
          <li className="list-zones-item isolation">
            <p className="list-zones-title">Zone 3 : Isolation {radiationLevel.zone3}</p>
            <CardZoneDistance zone={zones.zone3 } />
          </li>
        </ul>

        { zones.no_fly &&
          <ul className="list-aerial">
            <li>
              <span className="list-aerial-icon">
                <AerialForbiddenIcon />
              </span>
              <div>
                <p className="list-aerial-title">Zone de survol interdite</p>
                <p className="list-aerial-text">{ zones.no_fly }m</p>
              </div>
              
            </li>
          </ul>
        }

        { zones.noise &&
        <ul className="list-noise">
          <li>
            <span className="list-aerial-icon">
              <NoiseIcon />
            </span>
            <div>
              <p className="list-noise-title">Bruit supérieur à 90 dB jusqu'à :</p>
              <p className="list-noise-text">{ zones.noise }m</p>
            </div>
          </li>
        </ul>
        }
      </div>
    );
  }

  return (
    <Fragment>
      <div className="card--answer-header">

        <h1 className="h2 card--answer-header-text">
            { card.label }
        </h1>

        <span>
          { section && <span className="card--answer-header-section">{section} mm</span> }
        </span>

        <button className="card--answer-header-button" onClick={() => cardToggle(cardId)} />

      </div>

      <div className="card--answer-content">

        { (section && card.zones[section]) &&
          <CardZone zones={card.zones[section]} radiation={card.radiation} />
        }

        { card.comment &&
          <CardComment card={card} section={section} cardToggle={() => cardToggle(cardId)} addNewCard={addNewCard} />
        }

        <Button label="Voir le schéma" Icon={EyeIcon} className="card--answer-content-show" onClick={() => toggleRotationOverlay()} />

        <Button label="Supprimer" buttonStyle="warning" Icon={TrashIcon} className="card--answer-content-delete" onClick={() => removeCard(cardId)} />

      </div>

    </Fragment>
  )
};

export default Answer;
