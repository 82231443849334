const productQuestion = {
  field: "product",

  title: "Choix du Produit",
  content: "Quel est le produit ?",

  options: [
    {
      value: "naturalgaz",
      label: "Gaz Naturel",
      next: 'presure',
    },

    {
      value: "ethylene",
      label: "Éthylène",
      next: 'fire',
    },

    {
      value: "hcl",
      label: "Hydrocarbures liquides",
      next: 'fire_hcl',
    },

    {
      value: "mcv",
      label: "Monochlorure de vinyle",
      next: 'fire',
    },

    {
      value: "propylene",
      label: "Propylène",
      next: 'fire',
    },

    {
      value: "oxygen",
      label: "Oxygène",
      next: 'leak_oxygen'
    }
  ]
};

export default productQuestion;
