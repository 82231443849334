import pipeQuestion from "./questions/pipe-question";
import productQuestion from "./questions/product-question";
import {fireHclQuestion, fireNaturalGasQuestion, fireQuestion} from "./questions/fire-question";
import presureQuestion from "./questions/presure-question";
import {leakHclQuestion, leakHclFireQuestion, leakOxygenQuestion, leakQuestion} from "./questions/leak-question";
import {atmosphericQuestion, atmosphericHclQuestion} from "./questions/atmospheric-question";
import {streamQuestion} from "./questions/stream-question";

export const mapQuestions = {
  start: productQuestion, // first question
  product: productQuestion,

  fire: fireQuestion,
  fire_natural_gas: fireNaturalGasQuestion,
  fire_hcl: fireHclQuestion,

  presure: presureQuestion,

  atmospheric: atmosphericQuestion,
  atmospheric_hcl: atmosphericHclQuestion,

  stream: streamQuestion,

  leak: leakQuestion,
  leak_oxygen: leakOxygenQuestion,
  leak_hcl: leakHclQuestion,
  leak_hcl_fire: leakHclFireQuestion,

  pipe: pipeQuestion,
};

export default mapQuestions;