export const streamQuestion = {
  field: "leak",

  title: "Jet",
  content: "Quel est le type de jet ?",

  options: [
    {
      value: "novertical",
      label: "Pas de jet vertical",
      next: 'pipe'
    },
    {
      value: "vertical",
      label: "Vertical",
      next: 'pipe'
    }
  ]
};
