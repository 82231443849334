import React from 'react';

// Import questions config
import mapQuestions from '../../config/questions';

// Import components
import OptionList from '../question-option-list';

// Immport assets
import PrevIcon from '../../assets/icons/Prev';

class Questions extends React.Component {

  state = {
    question: mapQuestions.start,
    answer: [],
  }

  handleOptionClick = (option) =>  {
    // If there is no more questions, triggers the answer rendering by giving the function the generated classId
    if (!option.next) {
        this.props.getAnswer([...this.state.answer, option.value || option].join('-'));
        return;
    }
    
    this.setState({
      answer: [...this.state.answer, option.value || option],
      question: option.next ? mapQuestions[ option.next ] : false,
    })
  }

  previousQuestion = () => {

    const answers = this.state.answer;
    answers.pop();
    
    let question = mapQuestions.start;

    // eslint-disable-next-line no-unused-vars
    for (let item in answers) {
      
      const answer = answers[item];
      const options = question.options;

      let option;

      for (let i = 0; i < options.length; i++) {
        
        // eslint-disable-next-line no-unused-vars
        for(let key in question.options[i]) {

          if(question.options[i][key] === answer) {
            option = question.options[i];
          }
        }
      }

      if (option.next) {
        question = mapQuestions[option.next];
      }
    }

    this.setState({
      answer: answers,
      question: question
    })

  }

  handleResetClick = () => {
    this.setState({
      question: mapQuestions.start,
      answer: [],
    });
  }

  render () {
    return (
      <div className="questions">
        
        <h1 className="h1 questions-title">{ this.state.question.content }</h1>

        { this.state.answer.length > 0 && 
          <button className="questions-previous" onClick={this.previousQuestion}><span className="questions-previous-icon"><PrevIcon /></span></button>
        }

        { this.state.question &&
          <OptionList question={this.state.question} answer={this.state.answer} handleOptionClick={this.handleOptionClick}/>
        }

      </div>
    )
  }
}

export default Questions;
