export const presureQuestion = {
  field: "presure",

  title: "Pression",
  content: "Quelle est la pression ?",

  options: [
    {
      value: "high",
      label: "HP - Haute pression",
      next: 'fire_natural_gas'
    },
    {
      value: "medium",
      label: "MP - Moyenne pression",
      next: 'fire_natural_gas'
    }
  ]
};

export default presureQuestion;
