import React from 'react';

const TrashIcon = () => (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 8.42857H41"strokeWidth="4.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M26.0714 3H17.9285C17.2086 3 16.5183 3.28597 16.0092 3.795C15.5002 4.30402 15.2142 4.99441 15.2142 5.71429V8.42857H28.7857V5.71429C28.7857 4.99441 28.4997 4.30402 27.9907 3.795C27.4816 3.28597 26.7912 3 26.0714 3Z" strokeWidth="4.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.9286 31.5V17.9286" strokeWidth="4.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M26.0714 31.5V17.9286" strokeWidth="4.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M34.4224 38.5101C34.3661 39.1888 34.0567 39.8216 33.5555 40.2828C33.0544 40.7441 32.3982 41.0001 31.7171 41H12.2846C11.6036 41.0001 10.9474 40.7441 10.4462 40.2828C9.94513 39.8216 9.63571 39.1888 9.57941 38.5101L7.07141 8.42857H36.9286L34.4224 38.5101Z" strokeWidth="4.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

);

export default TrashIcon;

