export const fireQuestion = {
  field: "fire",

  title: "Incendie",
  content: "Avec ou sans incendie ?",

  options: [
    {
      value: "fire",
      label: "Incendie",
      next: 'leak'
    },

    {
      value: "nofire",
      label: "Pas d'inflammation",
      next: 'atmospheric'
    }
  ]
};

export const fireNaturalGasQuestion = {
  ...fireQuestion,

  options: [
    {
      value: "fire",
      label: "Incendie",
      next: 'leak'
    },

    {
      value: "nofire",
      label: "Pas d'inflammation",
      next: 'leak'
    }
  ]
};

export const fireHclQuestion = {
  ...fireQuestion,

  options: [
    {
      value: "fire",
      label: "Incendie",
      next: 'leak_hcl_fire'
    },
    {
      value: "nofire",
      label: "Pas d'inflammation",
      next: 'leak_hcl'
    }
  ]
};
