import React, {Fragment} from 'react';

// Import questions config
import matchAnswer from "../../config/answers";
import cardPicturesIndex from '../../config/cardPicturesIndex';

// Import assets
import CloseIcon from '../../assets/icons/Add';
import PrevIcon from '../../assets/icons/Prev';
import NextIcon from '../../assets/icons/Next';
import LinkIcon from '../../assets/icons/Link';

class FullScreen extends React.Component {

    state = {
        currentCard: this.props.fullSccreenCurrentCard ? this.props.fullSccreenCurrentCard : 1,
        prevCard: 0,
        nextCard: this.props.cardsArray[2] && 2,
        controls: true
    }

    toggleControls = () => {
        this.setState((prevState) => ({
            controls: !prevState.controls,
          }))
    }

    nextCard = () => {
        this.setState((prevState) => ({
            currentCard: prevState.currentCard + 1,
            prevCard: prevState.currentCard,
            nextCard: this.props.cardsArray[prevState.nextCard + 1] && prevState.nextCard + 1,
            controls: true
        }))
    }

    prevCard = () => {
        this.setState((prevState) => ({
            currentCard: prevState.prevCard,
            prevCard: this.props.cardsArray[prevState.prevCard - 1] && prevState.prevCard - 1,
            nextCard: prevState.currentCard,
            controls: true
        }))
    }

    linkToAnOtherCard = (cardId) => {
        // Add new card to the list
        this.props.addNewCard(cardId);

        let redirectCard;

        // Find the card in the cards array by comparing the cardId key
        for (let i = 0; i < this.props.cardsArray.length; i++) {
            
            // eslint-disable-next-line no-unused-vars
            for(let key in this.props.cardsArray[i]) {
                if(this.props.cardsArray[i][key] === cardId) {
                    redirectCard = i;
                }
            }
        }
        
        // Set current card to the redirect card
        this.setState({
            currentCard: redirectCard,
            prevCard: (this.props.cardsArray[redirectCard - 1] !== false) ? redirectCard - 1 : 0,
            nextCard: this.props.cardsArray[redirectCard + 1] && redirectCard + 1,
            controls: true
        })
    }
 
    render () {

        const currentCard = this.props.cardsArray[this.state.currentCard].cardId;

        const answer = currentCard.split('-');

        let card = matchAnswer(answer, true);
        let section = false;
        if (!card) {
            section = answer.pop();
            card = matchAnswer(answer, true);
        }

        const pictureLink = answer.join().replace(/,/g, "-");

        return (
            <div className="fullscreen">

                {this.state.controls &&
                    <div className="fullscreen-controls-header">
                        <p className="fullscreen-controls-header-text">{card.label}</p>
                        <button onClick={this.props.toggleFullScreen} className="fullscreen-controls-header-icon"><CloseIcon /></button>
                    </div>
                }

                <div className="fullscreen-image">
                    {this.state.prevCard !== 0 && this.state.controls &&
                        <button onClick={this.prevCard} className="fullscreen-controls-button-prev">
                            <span className="fullscreen-controls-button-prev-icon"><PrevIcon /></span>
                        </button>
                    }

                    <img onClick={this.toggleControls} alt={pictureLink} src={cardPicturesIndex[pictureLink]} className="fullscreen-image-picture"></img>

                    {this.state.nextCard && this.state.controls &&
                        <button onClick={this.nextCard} className="fullscreen-controls-button-next">
                            <span className="fullscreen-controls-button-next-icon"><NextIcon /></span>
                        </button>
                    }
                </div>

                {this.state.controls &&
                    <div className="fullscreen-controls-footer">
                        {card.comment &&
                            card.redirect ?
                                <Fragment>
                                    <p className="fullscreen-controls-footer-text">{card.comment.text}</p>
                                    <button onClick={() => this.linkToAnOtherCard(card.redirect + section)} className="fullscreen-controls-footer-link">
                                        {card.comment.label}
                                        <span className="fullscreen-controls-footer-link-icon"><LinkIcon /></span>
                                    </button>
                                </Fragment>
                            :
                                <p className="fullscreen-controls-footer-text">{card.comment}</p>
                        }
                    </div>
                }
            </div>
        )
    }
};

export default FullScreen;
