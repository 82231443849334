    import React from 'react';

    const NextIcon = () => (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.85">
            <path d="M13.488 7.50598H1.5" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.69287 13.5L13.4881 7.50598L8.69287 1.51196" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
        </svg>
    );

    export default NextIcon;
    
