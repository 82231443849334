export const atmosphericQuestion = {
  field: "atmos",

  title: "Class Atmospherique",
  content: "Quelle est la classe atmospherique ?",

  options: [
    {
      value: "d5",
      label: "D5 (jour, vent de 18km/h)",
      next: 'leak',
    },

    {
      value: "f2",
      label: "F2 (nuit, pas de vent ou vent de 8km/h)",
      next: 'leak',
    }
  ]
};

export const atmosphericHclQuestion = {
  ...atmosphericQuestion,

  options: [
    {
      value: "d5",
      label: "D5 (jour, vent de 18km/h)",
      next: 'pipe',
    },

    {
      value: "f2",
      label: "F2 (nuit, pas de vent ou vent de 8km/h)",
      next: 'pipe',
    }
  ]
};