import React from 'react';

import NewCard from '../elements/NewCard';
import Questions from '../elements/Questions';
import Answer from '../elements/Answer';

class Card extends React.Component {

    state = {
        cardStatus: this.props.status,
        cardId: this.props.cardId
    }
   
    getQuestions = () => {
        this.setState({
            cardStatus: 'questions'
        })
    }

    getAnswer = (cardId) => {
        this.setState({ cardStatus: 'new'},
            ()=> {
                this.props.addNewCard(cardId);    
            }
        );
    }

    render () {
        const order = this.props.index !== 0 ? -this.props.index : -1000;
        return (
            <div 
              style={{order:order}}
              className={"card card--" + this.state.cardStatus +' ' + (this.state.cardId ? this.state.cardId : '')}>
                {this.state.cardStatus === 'new' && <NewCard getQuestions={this.getQuestions} />}
                {this.state.cardStatus === 'questions' && <Questions getAnswer={this.getAnswer} />}
                {this.state.cardStatus === 'answer' && 
                    <Answer 
                        cardId={this.state.cardId} 
                        addNewCard={this.props.addNewCard} 
                        removeCard={this.props.removeCard} 
                        cardToggle={this.props.cardToggle} 
                        toggleRotationOverlay={this.props.toggleRotationOverlay}
                    />
                }
            </div>
        )
    }
}

export default Card;