import React from 'react';

const AerialForbidden = () => (
    <svg viewBox="0 0 40 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.7125 41.0004C24.1578 41.0006 23.6118 40.8634 23.1231 40.6012C22.6344 40.3389 22.2182 39.9597 21.9117 39.4975C21.6051 39.0352 21.4178 38.5043 21.3665 37.952C21.3151 37.3998 21.4013 36.8434 21.6173 36.3325C21.8333 35.8217 22.1724 35.3723 22.6044 35.0244C23.0364 34.6765 23.5478 34.441 24.093 34.3389C24.6381 34.2367 25.2001 34.2712 25.7287 34.4391C26.2573 34.607 26.7361 34.9032 27.1224 35.3012C27.3493 34.4993 27.7955 33.7766 28.4108 33.2144C29.026 32.6522 29.7859 32.2728 30.605 32.1188C31.4241 31.9649 32.2699 32.0425 33.0472 32.343C33.8246 32.6434 34.5028 33.1548 35.0054 33.8196C35.5081 34.4844 35.8153 35.2762 35.8925 36.106C35.9697 36.9359 35.8139 37.7708 35.4425 38.5169C35.0712 39.2631 34.4991 39.8908 33.7905 40.3295C33.0819 40.7683 32.2649 41.0006 31.4315 41.0004H24.7125Z" stroke="#1F1F1F" strokeWidth="4.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M33.1112 24.2032C34.1507 24.2032 35.1476 23.7903 35.8826 23.0553C36.6177 22.3202 37.0306 21.3233 37.0306 20.2838V20.2838C37.0306 19.2443 36.6177 18.2474 35.8826 17.5124C35.1476 16.7773 34.1507 16.3644 33.1112 16.3644H11.2744L7.91489 10.7652H3.43555V17.4842C3.43555 19.2662 4.14344 20.9752 5.4035 22.2353C6.66356 23.4954 8.37257 24.2032 10.1546 24.2032H15.7537V29.7532C15.7538 29.9627 15.8127 30.168 15.9237 30.3457C16.0346 30.5234 16.1932 30.6664 16.3814 30.7585C16.5696 30.8506 16.7799 30.888 16.9883 30.8665C17.1967 30.8451 17.3949 30.7656 17.5604 30.6371L25.8323 24.2032H33.1112Z" stroke="#1F1F1F" strokeWidth="4.5" strokeLinecap="round" strokeLinejoin="round"/>
        <mask id="path-3-outside-1" maskUnits="userSpaceOnUse" x="-0.263672" y="-0.263641" width="40" height="40" fill="black">
            <rect fill="white" x="-0.263672" y="-0.263641" width="40" height="40"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M36.6659 3.39537C37.5446 4.27405 37.5446 5.69867 36.6659 6.57735L6.57732 36.6659C5.69864 37.5446 4.27402 37.5446 3.39534 36.6659C2.51666 35.7872 2.51666 34.3626 3.39534 33.4839L33.4839 3.39537C34.3626 2.51669 35.7872 2.51669 36.6659 3.39537Z"/>
        </mask>
        <path fillRule="evenodd" clipRule="evenodd" d="M36.6659 3.39537C37.5446 4.27405 37.5446 5.69867 36.6659 6.57735L6.57732 36.6659C5.69864 37.5446 4.27402 37.5446 3.39534 36.6659C2.51666 35.7872 2.51666 34.3626 3.39534 33.4839L33.4839 3.39537C34.3626 2.51669 35.7872 2.51669 36.6659 3.39537Z" fill="#1F1F1F"/>
        <path d="M36.6659 6.57735L35.2241 5.13555L35.2241 5.13555L36.6659 6.57735ZM36.6659 3.39537L35.2241 4.83716L35.2241 4.83716L36.6659 3.39537ZM6.57732 36.6659L5.13552 35.2241L5.13552 35.2241L6.57732 36.6659ZM3.39534 36.6659L4.83713 35.2241L4.83713 35.2241L3.39534 36.6659ZM3.39534 33.4839L4.83713 34.9257L4.83713 34.9257L3.39534 33.4839ZM33.4839 3.39537L34.9257 4.83716L34.9257 4.83716L33.4839 3.39537ZM38.1077 8.01915C39.7826 6.34418 39.7826 3.62853 38.1077 1.95357L35.2241 4.83716C35.3065 4.91956 35.3065 5.05315 35.2241 5.13555L38.1077 8.01915ZM8.01911 38.1077L38.1077 8.01914L35.2241 5.13555L5.13552 35.2241L8.01911 38.1077ZM1.95354 38.1077C3.6285 39.7827 6.34415 39.7827 8.01911 38.1077L5.13552 35.2241C5.05312 35.3065 4.91953 35.3065 4.83713 35.2241L1.95354 38.1077ZM1.95354 32.0421C0.278582 33.7171 0.278582 36.4327 1.95354 38.1077L4.83713 35.2241C4.75473 35.1417 4.75473 35.0081 4.83713 34.9257L1.95354 32.0421ZM32.0421 1.95357L1.95354 32.0421L4.83713 34.9257L34.9257 4.83716L32.0421 1.95357ZM38.1077 1.95357C36.4327 0.278612 33.7171 0.278612 32.0421 1.95357L34.9257 4.83716C35.0081 4.75477 35.1417 4.75477 35.2241 4.83716L38.1077 1.95357Z" fill="#F7750A" mask="url(#path-3-outside-1)"/>
    </svg>
);

export default AerialForbidden;
