import React from 'react';

// Import components
import Button from './elements/Button';

function OptionList({question, answer, handleOptionClick}) {
  function QuestionOption({option, handleOptionClick}) {
    return (
      <li className="questions-list-item">
        <Button label={option.label || option} onClick={() => handleOptionClick(option) } buttonStyle="question" />
      </li>
    );
  }

  const options = ({}.toString.call(question.options)) === '[object Function]'
    ? question.options(answer)
    : question.options;

  return (
    <ul className="questions-list">
      { options.map((option) => <QuestionOption key={option.value || option} option={option} handleOptionClick={handleOptionClick} /> )}
    </ul>
  );
}

export default OptionList;