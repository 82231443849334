import React from 'react';

// Import icons
import AddIcon from '../../assets/icons/Add';

const NewCard = ({ getQuestions }) => (
    <button className="card--new-button" onClick={getQuestions}>
        <span className="card--new-button-icon">
            <AddIcon />
        </span>
        <p className="card--new-button-text">Ajouter un cas de figure</p>
    </button>
)

export default NewCard ;