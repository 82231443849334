import React from 'react';

const WarningIcon = () => (

    <svg viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.7187 31.4996C20.6199 31.4996 20.5232 31.5289 20.441 31.5839C20.3587 31.6388 20.2947 31.7169 20.2568 31.8083C20.219 31.8996 20.2091 32.0002 20.2284 32.0972C20.2477 32.1942 20.2953 32.2832 20.3652 32.3532C20.4351 32.4231 20.5242 32.4707 20.6212 32.49C20.7182 32.5093 20.8187 32.4994 20.9101 32.4616C21.0015 32.4237 21.0795 32.3596 21.1345 32.2774C21.1894 32.1952 21.2188 32.0985 21.2188 31.9996C21.2188 31.867 21.1661 31.7398 21.0723 31.6461C20.9785 31.5523 20.8514 31.4996 20.7187 31.4996Z" fill="#1F1F1F" stroke="#1F1F1F" strokeWidth="4.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20.8125 25.7496V13.8746" stroke="#1F1F1F" strokeWidth="4.5" miterlimit="10" strokeLinecap="round"/>
        <path d="M23.3791 4.59879C23.1435 4.11906 22.7781 3.71498 22.3244 3.43239C21.8708 3.14979 21.347 3 20.8125 3C20.278 3 19.7543 3.14979 19.3006 3.43239C18.8469 3.71498 18.4816 4.11906 18.2459 4.59879L3.24702 35.154C3.06647 35.5212 2.9825 35.9285 3.00303 36.3372C3.02357 36.746 3.14793 37.1427 3.36438 37.4901C3.58083 37.8374 3.88223 38.1238 4.24012 38.3223C4.59801 38.5209 5.00059 38.6249 5.40985 38.6246H36.2152C36.6244 38.6249 37.027 38.5209 37.3849 38.3223C37.7428 38.1238 38.0442 37.8374 38.2606 37.4901C38.4771 37.1427 38.6015 36.746 38.622 36.3372C38.6425 35.9285 38.5586 35.5212 38.378 35.154L23.3791 4.59879Z" stroke="#1F1F1F" strokeWidth="4.5" strokeLinecap="round" strokeLinejoin="round"/>
        <circle cx="20.8125" cy="31.8121" r="1.5" fill="#1F1F1F" stroke="#1F1F1F"/>
    </svg>

);

export default WarningIcon;

