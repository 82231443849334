import { keys } from 'lodash';

import matchAnswer from '../answers';

const pipeQuestion = {
  field: "pipe",

  title: "Pipeline",
  content: "Quel est le diamètre (en mm) ?",

  options: function(answers) {
    const matches = matchAnswer(answers, true);

    return matches && keys(matches.zones).map(value => ({
      value: value,
      label: `${value} mm`
    }));
  }
};

export default pipeQuestion;
