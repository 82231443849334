import { difference } from "lodash";

const answerCards = [
  {
    fields: ["naturalgaz", "high", "fire", "full"],
    label: "Gaz Naturel HP - Guillotine - Incendie",
    radiation: {zone2: "3 kW/m2 après 300s"},
    zones: {
      100: {zone1: 30, zone2: 60, zone3: 100, noise: 75, no_fly: null, hbd: 30},
      200: {zone1: 60, zone2: 140, zone3: 200, noise: 75, no_fly: 200, hbd: 60},
      300: {zone1: 80, zone2: 220, zone3: 300, noise: 75, no_fly: 300, hbd: 80},
      400: {zone1: 110, zone2: 290, zone3: 400, noise: 150, no_fly: 400, hbd: 110},
      500: {zone1: 130, zone2: 360, zone3: 500, noise: 150, no_fly: 500, hbd: 130},
      600: {zone1: 140, zone2: 430, zone3: 600, noise: 150, no_fly: 600, hbd: 140},
      700: {zone1: 160, zone2: 500, zone3: 700, noise: 150, no_fly: 700, hbd: 160},
      800: {zone1: 180, zone2: 570, zone3: 800, noise: 250, no_fly: 800, hbd: 180},
      900: {zone1: 190, zone2: 640, zone3: 900, noise: 250, no_fly: 900, hbd: 190},
      1000: {zone1: 210, zone2: 710, zone3: 1000, noise: 250, no_fly: 1000, hbd: 210},
      1200: {zone1: 230, zone2: 800, zone3: 1200, noise: 250, no_fly: 1200, hbd: 230},
    }
  },
  {
    fields: ["naturalgaz", "high", "nofire", "full"],
    label: "Gaz Naturel HP - Guillotine - Pas d'inflammation",
    radiation: {zone1: "10 kW/m2 après 30s", zone2: "3 kW/m2 après 30s"},
    zones: {
      100:  {zone1: 50, zone2: 90, zone3: 100, hbd: 30, noise: 75, no_fly: null},
      200:  {zone1: 100, zone2: 210, zone3: 200, hbd: 60, noise: 75, no_fly: 200},
      300:  {zone1: 150, zone2: 320, zone3: 300, hbd: 80, noise: 75, no_fly: 300},
      400:  {zone1: 200, zone2: 430, zone3: 400, hbd: 110, noise: 150, no_fly: 400},
      500:  {zone1: 240, zone2: 520, zone3: 500, hbd: 130, noise: 150, no_fly: 500},
      600:  {zone1: 290, zone2: 610, zone3: 600, hbd: 140, noise: 150, no_fly: 600},
      700:  {zone1: 340, zone2: 700, zone3: 700, hbd: 160, noise: 150, no_fly: 700},
      800:  {zone1: 380, zone2: 780, zone3: 800, hbd: 180, noise: 250, no_fly: 800},
      900:  {zone1: 430, zone2: 870, zone3: 900, hbd: 190, noise: 250, no_fly: 900},
      1000: {zone1: 470, zone2: 960, zone3: 1000, hbd: 210, noise: 250, no_fly: 1000},
      1200: {zone1: 560, zone2: 1130, zone3: 1200, hbd: 230, noise: 250, no_fly: 1200},
    }
  },
  {
    fields: ["naturalgaz", "high", "fire", "partial"],
    label: "Gaz Naturel HP - Fuite 10% - Incendie",
    radiation: {zone2: "3 kW/m2 après 300s"},
    zones: {
      100: {zone1: 5, zone2: 10, zone3: 50, noise: 75, no_fly: null, hbd: 5},
      200: {zone1: 10, zone2: 25, zone3: 50, noise: 75, no_fly: null, hbd: 10},
      300: {zone1: 10, zone2: 40, zone3: 50, noise: 75, no_fly: null, hbd: 10},
      400: {zone1: 15, zone2: 50, zone3: 100, noise: 150, no_fly: null, hbd: 15},
      500: {zone1: 15, zone2: 65, zone3: 100, noise: 150, no_fly: null, hbd: 15},
      600: {zone1: 15, zone2: 80, zone3: 100, noise: 150, no_fly: null, hbd: 15},
      700: {zone1: 20, zone2: 90, zone3: 150, noise: 150, no_fly: 700, hbd: 20},
      800: {zone1: 20, zone2: 105, zone3: 150, noise: 250, no_fly: 800, hbd: 20},
      900: {zone1: 25, zone2: 120, zone3: 150, noise: 250, no_fly: 900, hbd: 25},
      1000: {zone1: 25, zone2: 135, zone3: 150, noise: 250, no_fly: 1000, hbd: 25},
      1200: {zone1: 30, zone2: 160, zone3: 200, noise: 250, no_fly: 1200, hbd: 30},
    }
  },
  {
    fields: ["naturalgaz", "high", "nofire", "partial"],
    label: "Gaz Naturel HP - Fuite 10% - Pas d'inflammation",
    radiation: {zone1: "10 kW/m2 après 30s", zone2: "3 kW/m2 après 30s"},
    zones: {
      100: {zone1: 5, zone2: 10, zone3: 50, hbd: 5, noise: 75, no_fly: null},
      200: {zone1: 10, zone2: 25, zone3: 50, hbd: 10, noise: 75, no_fly: null},
      300: {zone1: 15, zone2: 40, zone3: 50, hbd: 10, noise: 75, no_fly: null},
      400: {zone1: 20, zone2: 50, zone3: 100, hbd: 15, noise: 150, no_fly: null},
      500: {zone1: 30, zone2: 65, zone3: 100, hbd: 15, noise: 150, no_fly: null},
      600: {zone1: 35, zone2: 80, zone3: 100, hbd: 15, noise: 150, no_fly: null},
      700: {zone1: 40, zone2: 95, zone3: 150, hbd: 20, noise: 150, no_fly: 700},
      800: {zone1: 45, zone2: 105, zone3: 150, hbd: 20, noise: 250, no_fly: 800},
      900: {zone1: 50, zone2: 120, zone3: 150, hbd: 25, noise: 250, no_fly: 900},
      1000: {zone1: 60, zone2: 135, zone3: 150, hbd: 25, noise: 250, no_fly: 1000},
      1200: {zone1: 70, zone2: 160, zone3: 200, hbd: 30, noise: 250, no_fly: 1200},
    }
  },
  {
    fields: ["naturalgaz", "medium", "fire", "full"],
    label: "Gaz Naturel MP - Guillotine - Incendie",
    radiation: {zone2: "3 kW/m2 après 300s"},
    zones: {
      100: {zone1: 20, zone2: 30, zone3: 50, noise: 75, no_fly: null, hbd: 20},
      200: {zone1: 30, zone2: 70, zone3: 100, noise: 75, no_fly: null, hbd: 30},
      300: {zone1: 50, zone2: 110, zone3: 150, noise: 75, no_fly: 300, hbd: 50},
      400: {zone1: 70, zone2: 150, zone3: 200, noise: 150, no_fly: 400, hbd: 70},
      500: {zone1: 80, zone2: 180, zone3: 250, noise: 150, no_fly: 500, hbd: 80},
      600: {zone1: 90, zone2: 220, zone3: 300, noise: 150, no_fly: 600, hbd: 90}
    }
  },
  {
    fields: ["naturalgaz", "medium", "nofire", "full"],
    label: "Gaz Naturel MP - Guillotine - Pas d'inflammation",
    radiation: {zone1: "10 kW/m2 après 30s", zone2: "3 kW/m2 après 30s"},
    zones: {
      100: {zone1: 30, zone2: 50, zone3: 50, noise: 75, no_fly: null, hbd: 20},
      200: {zone1: 60, zone2: 110, zone3: 100, noise: 75, no_fly: null, hbd: 30},
      300: {zone1: 80, zone2: 160, zone3: 150, noise: 75, no_fly: 300, hbd: 50},
      400: {zone1: 110, zone2: 210, zone3: 200, noise: 150, no_fly: 400, hbd: 70},
      500: {zone1: 130, zone2: 260, zone3: 250, noise: 150, no_fly: 500, hbd: 80},
      600: {zone1: 150, zone2: 310, zone3: 300, noise: 150, no_fly: 600, hbd: 90},
    }
  },
  {
    fields: ["naturalgaz", "medium", "fire", "partial"],
    label: "Gaz Naturel MP - Fuite 10% - Incendie",
    radiation: {zone2: "3 kW/m2 après 300s"},
    zones: {
      100: {zone1: 5, zone2: 10, zone3: 25, noise: 75, no_fly: null, hbd: 5},
      200: {zone1: 5, zone2: 10, zone3: 25, noise: 75, no_fly: null, hbd: 5},
      300: {zone1: 10, zone2: 15, zone3: 25, noise: 75, no_fly: null, hbd: 10},
      400: {zone1: 10, zone2: 20, zone3: 50, noise: 150, no_fly: null, hbd: 10},
      500: {zone1: 10, zone2: 25, zone3: 50, noise: 150, no_fly: null, hbd: 10},
      600: {zone1: 10, zone2: 35, zone3: 50, noise: 150, no_fly: null, hbd: 10}
    }
  },
  {
    fields: ["naturalgaz", "medium", "nofire", "partial"],
    label: "Gaz Naturel MP - Fuite 10% - Pas d'inflammation",
    radiation: {zone1: "10 kW/m2 après 30s", zone2: "3 kW/m2 après 30s"},
    zones: {
      100: {zone1: 5, zone2: 10, zone3: 25, noise: 75, no_fly: null, hbd: 5},
      200: {zone1: 5, zone2: 10, zone3: 25, noise: 75, no_fly: null, hbd: 5},
      300: {zone1: 10, zone2: 15, zone3: 25, noise: 75, no_fly: null, hbd: 10},
      400: {zone1: 10, zone2: 20, zone3: 50, noise: 150, no_fly: null, hbd: 10},
      500: {zone1: 10, zone2: 25, zone3: 50, noise: 150, no_fly: null, hbd: 10},
      600: {zone1: 15, zone2: 35, zone3: 50, noise: 150, no_fly: null, hbd: 10}
    }
  },

  // ------------------------------------------------------------------------------------------------------------------

  {
    fields: ["ethylene", "fire", "full"],
    label: "Ethylène - Rupture guillotine - Incendie",
    radiation: {zone2: "3 kW/m2 après 300s", zone3: "1 kW/m2 après 300s"},
    zones: {
      200: {zone1: 65, zone2: 125, zone3: 260, noise: 75, no_fly: 200, hbd: 65},
      250: {zone1: 75, zone2: 155, zone3: 315, noise: 75, no_fly: 250, hbd: 75},
      300: {zone1: 90, zone2: 220, zone3: 365, noise: 75, no_fly: 300, hbd: 90},
      550: {zone1: 105, zone2: 315, zone3: 550, noise: 150, no_fly: 550, hbd: 105},
    }
  },
  {
    fields: ["ethylene", "nofire", "d5", "partial"],
    label: "Ethylène - Fuite 10% - Pas d'inflammation - vent 18km/h - D5",
    zones: {
      200: {zone1: [35,70,85], zone2: [75,150,180], zone3: [125,250,310], noise: 75, no_fly: 200},
      250: {zone1: [45,90,120], zone2: [90,180,230], zone3: [150,300,385], noise: 75, no_fly: 250},
      300: {zone1: [50,100,155], zone2: [105,210,275], zone3: [175,400,455], noise: 75, no_fly: 300},
      550: {zone1: [25,50,65], zone2: [70,140,170], zone3: [125,250,340], noise: 150, no_fly: 550}
    },
    comment: {
      text: "En cas d'inflammation, utilisez la carte d'action :",
      label: "Ethylene - Fuite 10% - Incendie"
    },
    redirect: "ethylene-fire-partial-"
  },
  {
    fields: ["ethylene", "nofire", "f2", "partial"],
    label: "Ethylène - Fuite 10% - Pas d'inflammation - Pas de vent 8km/h - F2",
    zones: {
      200: {zone1: [35,70,135], zone2: [75,180,300], zone3: [125,405,475], noise: 75, no_fly: 200},
      250: {zone1: [45,90,170], zone2: [90,230,370], zone3: [150,520,580], noise: 75, no_fly: 250},
      300: {zone1: [50,100,200], zone2: [105,280,440], zone3: [185,620,685], noise: 75, no_fly: 300},
      550: {zone1: [25,50,110], zone2: [70,140,310], zone3: [125,290,585], noise: 150, no_fly: 550}
    },
    comment: {
      text: "En cas d'inflammation, utilisez la carte d'action :",
      label: "Ethylene - Fuite 10% - Incendie"
    },
    redirect: "ethylene-fire-partial-"
  },
  {
    fields: ["ethylene", "fire", "partial"],
    label: "Ethylène - Fuite de 10% - Incendie",
    radiation: {zone2: "3 kW/m2 après 300s", zone3: "1 kW/m2 après 300s"},
    zones: {
      200: {zone1: 30, zone2: 75, zone3: 125, noise: 75, no_fly: null, hbd: 30},
      250: {zone1: 40, zone2: 90, zone3: 150, noise: 75, no_fly: 250, hbd: 40},
      300: {zone1: 45, zone2: 105, zone3: 175, noise: 75, no_fly: 300, hbd: 45},
      550: {zone1: 20, zone2: 70, zone3: 125, noise: 150, no_fly: null, hbd: 20},
    }
  },

  {
    fields: ["ethylene", "nofire", "f2", "full"],
    label: "Ethylène - Rupture guillotine - Pas d'inflammation - Pas de vent 8km/h - F2",
    zones: {
      200: {zone1: [175,350,575], zone2: [355,710,1095], zone3: [605,1520,1830], noise: 75, no_fly: 200},
      250: {zone1: [215,430,710], zone2: [425,850,1385], zone3: [735,1990,2365], noise: 75, no_fly: 250},
      300: {zone1: [240,480,845], zone2: [480,960,1675], zone3: [940,2520,2900], noise: 75, no_fly: 300},
      550: {zone1: [355,710,1075], zone2: [690,1380,1735], zone3: [1305,3320,5805], noise: 150, no_fly: 550}
    },
    comment: {
      text: "En cas d'inflammation, utilisez la carte d'action :",
      label: "Ethylène - Rupture guillotine - INCENDIE"
    },
    redirect: "ethylene-fire-full-"
  },
  {
    fields: ["ethylene", "nofire", "d5", "full"],
    label: "Ethylène - Rupture guillotine - Pas d'inflammation - Vent 18km/h - D5",
    zones: {
      200: {zone1: [175,350,605], zone2: [355,710,715], zone3: [605,1330,1130], noise: 75, no_fly: 200},
      250: {zone1: [215,430,780], zone2: [425,850,920], zone3: [735,1780,1450], noise: 75, no_fly: 250},
      300: {zone1: [240,480,970], zone2: [480,960,1135], zone3: [815,2320,1790], noise: 75, no_fly: 300},
      550: {zone1: [355,710,890], zone2: [690,1380,1420], zone3: [1175,2880,2950], noise: 150, no_fly: 550}
    },
    comment: {
      text: "En cas d'inflammation, utilisez la carte d'action :",
      label: "Ethylène - Rupture guillotine - INCENDIE"
    },
    redirect: "ethylene-fire-full-"
  },

  // ------------------------------------------------------------------------------------------------------------------

  {
    fields: ["hcl", "fire", "partial"],
    label: "HCL - Fuite 10% - Incendie",
    radiation: {zone1: "10 kW/m2", zone2: "3 kW/m2", zone3: "1 kW/m2"},
    zones: {
      300: {zone1: 110, zone2: 155, zone3: 220, noise: null, no_fly: 300},
      450: {zone1: 155, zone2: 215, zone3: 310, noise: null, no_fly: 450},
      850: {zone1: 260, zone2: 360, zone3: 520, noise: null, no_fly: 850},
    },
    comment: {
      text: "En cas de feux de flaque sans feux de torche, utilisez la carte d'action :",
      label: "INCENDIE - rupture"
    },
    redirect: "hcl-fire-full-"
  },
  {
    fields: ["hcl", "nofire", "novertical", "partial"],
    label: "HCL - Fuite 10% - Pas d'inflammation - Pas de jet vertical",
    zones: {
      300: {zone1: [30,60,60], zone2: [90,180,130], zone3: [140,280,400], noise: null, no_fly: 300},
      450: {zone1: [50,100,90], zone2: [140,280,220], zone3: [220,440,620], noise: null, no_fly: 450},
      850: {zone1: [110,220,200], zone2: [280,560,470], zone3: [440,880,1150], noise: null, no_fly: 850}
    }
  },
  {
    fields: ["hcl", "nofire", "vertical", "partial"],
    label: "HCL - Fuite 10% - Pas d'inflammation - Jet vertical",
    zones: {
      300: {zone1: [110,220,110], zone2: [155,310,155], zone3: [220,440,400], noise: null, no_fly: 300},
      450: {zone1: [155,310,155], zone2: [215,430,220], zone3: [310,620,620], noise: null, no_fly: 450},
      850: {zone1: [260,520,260], zone2: [360,720,470], zone3: [520,1040,1150], noise: null, no_fly: 850}
    }
  },
  {
    fields: ["hcl", "fire", "full"],
    label: "HCL - Rupture guillotine - Incendie",
    radiation: {zone1: "10 kW/m2", zone2: "3 kW/m2", zone3: "1 kW/m2"},
    zones: {
      300: {zone1: 30, zone2: 90, zone3: 140, noise: null, no_fly: null},
      450: {zone1: 50, zone2: 140, zone3: 220, noise: null, no_fly: 450},
      850: {zone1: 110, zone2: 280, zone3: 440, noise: null, no_fly: 850},
    }
  },
  {
    fields: ["hcl", "nofire", "full", "f2"],
    label: "HCL - Rupture guillotine - Pas d'inflammation - Pas de vent 8km/h - F2",
    zones: {
      300: {zone1: [30,110,50], zone2: [90,180,90], zone3: [140,280,165], noise: null, no_fly: 300},
      450: {zone1: [50,440,155], zone2: [140,590,230], zone3: [450,1040,590], noise: null, no_fly: 450},
      850: {zone1: [110,1310,370], zone2: [325,1700,685], zone3: [1440,3240,1800], noise: null, no_fly: 850}
    },
    comment: "Utilisez cette carte d'action uniquement pour les substances volatiles (essence, pétrole, condensats de gaz, naphta)"
  },
  {
    fields: ["hcl", "nofire", "full", "d5"],
    label: "HCL - Rupture guillotine - Pas d'inflammation - Vent 18km/h - D5",
    radiation: {zone1: "10 kW/m2", zone2: "3 kW/m2", zone3: "1 kW/m2"},
    zones: {
      300: {zone1: 30, zone2: 90, zone3: 140, noise: null, no_fly: null},
      450: {zone1: 50, zone2: 140, zone3: 220, noise: null, no_fly: 450},
      850: {zone1: 110, zone2: 280, zone3: 440, noise: null, no_fly: 850},
    }
  },

  // ------------------------------------------------------------------------------------------------------------------

  {
    fields: ["mcv", "fire", "full"],
    label: "Monochlorure Vinyle - Guillotine - Incendie",
    radiation: {zone2: "3 kW/m2 après 300s", zone3: "1 kW/m2 après 300s"},
    zones: {
      100: {zone1: 30, zone2: 65, zone3: 105, noise: 75, no_fly: null, hbd: 30},
    }
  },
  {
    fields: ["mcv", "nofire", "d5", "partial"],
    label: "Monochlorure Vinyle - Fuite 10% - Pas d'inflammation - Vent 18km/h - D5",
    zones: {
      100: {zone1: [25,50,25], zone2: [40,80,45], zone3: [60,120,85], noise: 75, no_fly: null},
    }
  },
  {
    fields: ["mcv", "nofire", "f2", "partial"],
    label: "Monochlorure Vinyle - Fuite 10% - Pas d'inflammation - Pas de vent 8km/h - F2",
    zones: {
      100: {zone1: [25,50,25], zone2: [40,80,80], zone3: [60,160,130], noise: 75, no_fly: null},
    },
    comment: {
      text: "En cas d'inflammation, utilisez la carte d'action :",
      label: "MCV - Fuite de 10% - INCENDIE"
    },
    redirect: "mcv-fire-partial-"
  },

  {
    fields: ["mcv", "fire", "partial"],
    label: "Monochlorure Vinyle - Fuite 10% - Incendie",
    radiation: {zone2: "3 kW/m2 après 300s", zone3: "1 kW/m2 après 300s"},
    zones: {
      100: {zone1: 20, zone2: 40, zone3: 60, noise: 75, no_fly: null, hbd: 20},
    }
  },
  {
    fields: ["mcv", "nofire", "f2", "full"],
    label: "Monochlorure Vinyle - Rupture guillotine - Pas d'inflammation - Pas de vent 8km/h - F2",
    zones: {
      100: {zone1: [45,120,90], zone2: [80,170,125], zone3: [130,315,175], noise: 75, no_fly: 100},
    },
    comment: {
      text: "En cas d'inflammation, utilisez la carte d'action :",
      label: "MCV - Rupture guillotine - INCENDIE"
    },
    redirect: "mcv-fire-full-"
  },
  {
    fields: ["mcv", "nofire", "d5", "full"],
    label: "Monochlorure Vinyle - Rupture guillotine - Pas d'inflammation - Vent 18km/h - D5",
    zones: {
      100: {zone1: [45,90,70], zone2: [80,160,100], zone3: [130,260,150], noise: 75, no_fly: 100},
    },
    comment: {
      text: "En cas d'inflammation, utilisez la carte d'action :",
      label: "MCV - Rupture guillotine - INCENDIE"
    },
    redirect: "mcv-fire-full-"
  },

  // ------------------------------------------------------------------------------------------------------------------

  {
    fields: ["propylene", "fire", "full"],
    label: "Propylène - Rupture guillotine - Incendie",
    radiation: {zone2: "3 kW/m2 après 300s", zone3: "1 kW/m2 après 300s"},
    zones: {
      150: {zone1: 55, zone2: 115, zone3: 195, noise: 75, no_fly: 150, hbd: 55},
      200: {zone1: 70, zone2: 150, zone3: 250, noise: 75, no_fly: 200, hbd: 70},
    }
  },
  {
    fields: ["propylene", "nofire", "d5", "partial"],
    label: "Propylène - Fuite 10% - Pas d'inflammation - Vent 18km/h - D5",
    zones: {
      150: {zone1: [35, 70, 50], zone2: [65,130,120], zone3: [105,210,210], noise: 75, no_fly: 150},
      200: {zone1: [40,80,85], zone2: [80,160,165], zone3: [130,260,280], noise: 75, no_fly: 200},
    },
    comment: {
      text: "En cas d'inflammation, utilisez la carte d'action :",
      label: "Propylène - Fuite de 10% - INCENDIE"
    },
    redirect: "propylene-fire-partial-"
  },
  {
    fields: ["propylene", "nofire", "f2", "partial"],
    label: "Propylène - Fuite 10% - Pas d'inflammation - Pas de vent 8km/h - F2",
    zones: {
      150: {zone1: [35, 70, 85], zone2: [65, 130, 205], zone3: [105, 340, 310], noise: 75, no_fly: 150},
      200: {zone1: [40, 80, 115], zone2: [80, 190, 270], zone3: [130, 470, 410], noise: 75, no_fly: 200},
    },
    comment: {
      text: "En cas d'inflammation, utilisez la carte d'action :",
      label: "Propylène - Fuite de 10% - INCENDIE"
    },
    redirect: "propylene-fire-partial-"
  },
  {
    fields: ["propylene", "fire", "partial"],
    label: "Propylène - Fuite 10% - Incendie",
    radiation: {zone2: "3 kW/m2 après 300s", zone3: "1 kW/m2 après 300s"},
    zones: {
      150: {zone1: 30, zone2: 65, zone3: 105, noise: 75, no_fly: null, hbd: 30},
      200: {zone1: 35, zone2: 80, zone3: 130, noise: 75, no_fly: null, hbd: 35},
    }
  },
  {
    fields: ["propylene", "nofire", "f2", "full"],
    label: "Propylène - Rupture guillotine - Pas d'inflammation - Pas de vent 8km/h - F2",
    zones: {
      150: {zone1: [75, 390, 475], zone2: [145, 740, 575], zone3: [360, 1265, 805], noise: 75, no_fly: 150},
      200: {zone1: [100, 580, 660], zone2: [190, 1080, 815], zone3: [545, 1840, 1140], noise: 75, no_fly: 200},
    },
    comment: {
      text: "En cas d'inflammation, utilisez la carte d'action :",
      label: "Propylène - Rupture guillotine - INCENDIE"
    },
    redirect: "propylene-fire-full-"
  },
  {
    fields: ["propylene", "nofire", "d5", "full"],
    label: "Propylène - Rupture guillotine - Pas d'inflammation - Vent 18km/h - D5",
    zones: {
      150: {zone1: [75, 150, 300], zone2: [145, 290, 430], zone3: [260, 800, 680], noise: 75, no_fly: 150},
      200: {zone1: [100, 200, 415], zone2: [190, 380, 590], zone3: [420, 1240, 930], noise: 75, no_fly: 200},
    },
    comment: {
      text: "En cas d'inflammation, utilisez la carte d'action :",
      label: "Propylène - Rupture guillotine - INCENDIE"
    },
    redirect: "propylene-fire-full-"
  },

  // ------------------------------------------------------------------------------------------------------------------

  {
    fields: ["oxygen", "partial"],
    label: "Oxygène - Fuite 10%",
    comment: "La distance d'effet est toujours inférieur à 5m. Appliquer les distances de la procédure opérationnelle standardisée substances dangereuses."
  },

  {
    fields: ["oxygen", "full"],
    label: "Oxygène - Rupture Guillotine",
    conc: {zone1: 40, zone2: 25},
    zones: {
      150: {zone1: 5, zone2: 15, zone3: 100, noise: 75, no_fly: null},
      250: {zone1: 10, zone2: 35, zone3: 100, noise: 75, no_fly: null},
      400: {zone1: 20, zone2: 60, zone3: 100, noise: 75, no_fly: null},
    }
  },
];

// ====================+====================+====================+====================+====================+===========

function matchAnswer(answer, firstOnly=false) {
  const matches = answerCards.reduce((matches, card) => {
    const match = difference(answer, card.fields);

    if (!match.length) {
      matches.push( card );
    }
    return matches;
  }, []);

  return firstOnly ? matches[0] : matches;
}
export default matchAnswer;
