import React from 'react';

const Button = ({ label, className, onClick, buttonStyle, Icon, iconRight }) => (
    <button className={(className ? className + ' ' : '') + 'button' + (buttonStyle ? (' button--' + buttonStyle) : '')} onClick={(ev) => onClick(ev)}>
        {(Icon && !iconRight) && 
            <span className="button-icon-left"><Icon /></span>
        }

        {label}

        {(Icon && iconRight) &&
            <span className="button-icon-right"><Icon /></span>
        }
    </button>
)

export default Button ;