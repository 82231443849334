import React from 'react';

const NextIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.85">
            <path d="M2 7.99402L13.988 7.99402" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.79517 2L1.99995 7.99402L6.79517 13.988" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
    </svg>
);

export default NextIcon;