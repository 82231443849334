import React from 'react';

const RotationIcon = () => (
    <svg width="129" height="129" viewBox="0 0 129 129" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M55.0938 4.03125H9.40625C6.43772 4.03125 4.03125 6.43772 4.03125 9.40625V87.3438C4.03125 90.3123 6.43772 92.7188 9.40625 92.7188H55.0938C58.0623 92.7188 60.4688 90.3123 60.4688 87.3438V9.40625C60.4688 6.43772 58.0623 4.03125 55.0938 4.03125Z" stroke="white" strokeWidth="8.0625" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M44.3438 124.969C42.2054 124.969 40.1547 124.119 38.6427 122.607C37.1307 121.095 36.2812 119.045 36.2812 116.906" stroke="white" strokeWidth="8.0625" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M116.906 124.969C119.045 124.969 121.095 124.119 122.607 122.607C124.119 121.095 124.969 119.045 124.969 116.906" stroke="white" strokeWidth="8.0625" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M36.2812 92.7188V100.781" stroke="white" strokeWidth="8.0625" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M124.969 92.7188V100.781" stroke="white" strokeWidth="8.0625" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M60.4688 124.969H72.5625" stroke="white" strokeWidth="8.0625" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M88.6875 124.969H100.781" stroke="white" strokeWidth="8.0625" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M116.906 68.5312C119.045 68.5313 121.095 69.3807 122.607 70.8927C124.119 72.4047 124.969 74.4554 124.969 76.5938" stroke="white" strokeWidth="8.0625" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M60.4688 68.5312H72.5625" stroke="white" strokeWidth="8.0625" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M88.6875 68.5312H100.781" stroke="white" strokeWidth="8.0625" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M82.9846 8.0625L73.745 17.3075C73.3705 17.6814 73.0735 18.1255 72.8708 18.6143C72.6681 19.1031 72.5638 19.6271 72.5638 20.1562C72.5638 20.6854 72.6681 21.2094 72.8708 21.6982C73.0735 22.187 73.3705 22.6311 73.745 23.005L82.9846 32.25" stroke="white" strokeWidth="8.0625" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M96.75 46.0156L105.995 55.2552C106.369 55.6296 106.813 55.9267 107.302 56.1294C107.791 56.332 108.315 56.4364 108.844 56.4364C109.373 56.4364 109.897 56.332 110.386 56.1294C110.875 55.9267 111.319 55.6296 111.693 55.2552L120.938 46.0156" stroke="white" strokeWidth="8.0625" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M72.5625 20.1562H92.7188C96.9954 20.1563 101.097 21.8551 104.121 24.8792C107.145 27.9032 108.844 32.0046 108.844 36.2812V56.4375" stroke="white" strokeWidth="8.0625" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export default RotationIcon;