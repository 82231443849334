import React from 'react';

const NoiseIcon =() => (
    <svg viewBox="0 0 38 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 44.8505C3.94425 46.2517 5.31355 47.3127 6.90621 47.877C8.49887 48.4414 10.2307 48.4794 11.8465 47.9854C13.4624 47.4913 14.8769 46.4914 15.8817 45.1329C16.8864 43.7744 17.4283 42.1291 17.4275 40.4394C17.4275 39.2847 17.678 38.1438 18.1617 37.0953C18.6455 36.0469 19.3509 35.1158 20.2293 34.3664C21.4778 33.3012 22.4785 31.9762 23.1615 30.4839C23.8444 28.9916 24.1931 27.3682 24.1832 25.7271C24.1733 24.086 23.8051 22.4668 23.1041 20.9829C22.4032 19.499 21.3866 18.1861 20.1253 17.136C18.8641 16.086 17.3887 15.3242 15.8023 14.9038C14.2159 14.4834 12.5569 14.4147 10.9411 14.7024C9.32541 14.9901 7.79205 15.6273 6.44827 16.5694C5.1045 17.5115 3.98276 18.7359 3.1615 20.1567" stroke="#1F1F1F" strokeWidth="4.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M27.2606 3C29.5018 3 31.6512 3.8903 33.2359 5.47504C34.8206 7.05979 35.711 9.20916 35.711 11.4503" stroke="#1F1F1F" strokeWidth="4.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M23.8037 10.6821C24.9243 10.6821 25.999 11.1273 26.7914 11.9196C27.5837 12.712 28.0289 13.7867 28.0289 14.9073" stroke="#1F1F1F" strokeWidth="4.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.13965 23.7161C8.97662 23.0884 9.97186 22.7061 11.0139 22.6122C12.0558 22.5182 13.1034 22.7163 14.0392 23.1842C14.9749 23.652 15.7619 24.3713 16.312 25.2612C16.862 26.1512 17.1533 27.1767 17.1533 28.223" stroke="#1F1F1F" strokeWidth="4.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.99133 30.7197L9.39972 32.128" stroke="#1F1F1F" strokeWidth="4.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export default NoiseIcon;