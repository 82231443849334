import naturalgaz_high_fire_full from '../assets/images/cards/naturalgaz-high-fire-full.jpeg';
import naturalgaz_high_fire_partial from '../assets/images/cards/naturalgaz-high-fire-partial.jpeg';
import naturalgaz_high_nofire_full from '../assets/images/cards/naturalgaz-high-nofire-full.jpeg';
import naturalgaz_high_nofire_partial from '../assets/images/cards/naturalgaz-high-nofire-partial.jpeg';
import naturalgaz_medium_fire_full from '../assets/images/cards/naturalgaz-medium-fire-full.jpeg';
import naturalgaz_medium_nofire_full from '../assets/images/cards/naturalgaz-medium-nofire-full.jpeg';
import naturalgaz_medium_fire_partial from '../assets/images/cards/naturalgaz-medium-fire-partial.jpeg';
import naturalgaz_medium_nofire_partial from '../assets/images/cards/naturalgaz-medium-nofire-partial.jpeg';

import ethylene_fire_full from '../assets/images/cards/ethylene-fire-full.jpeg';
import ethylene_nofire_d5_partial from '../assets/images/cards/ethylene-nofire-d5-partial.jpeg';
import ethylene_nofire_f2_partial from '../assets/images/cards/ethylene-nofire-f2-partial.jpeg';
import ethylene_fire_partial from '../assets/images/cards/ethylene-fire-partial.jpeg';
import ethylene_nofire_f2_full from '../assets/images/cards/ethylene-nofire-f2-full.jpeg';
import ethylene_nofire_d5_full from '../assets/images/cards/ethylene-nofire-d5-full.jpeg';

import hcl_fire_partial from '../assets/images/cards/hcl-fire-partial.jpeg';
import hcl_nofire_novertical_partial from '../assets/images/cards/hcl-nofire-novertical-partial.jpeg';
import hcl_nofire_vertical_partial from '../assets/images/cards/hcl-nofire-vertical-partial.jpeg';
import hcl_fire_full from '../assets/images/cards/hcl-fire-full.jpeg';
import hcl_nofire_full_f2 from '../assets/images/cards/hcl-nofire-full-f2.jpeg';
import hcl_nofire_full_d5 from '../assets/images/cards/hcl-nofire-full-d5.jpeg';

import mcv_fire_full from '../assets/images/cards/mcv-fire-full.jpeg';
import mcv_nofire_d5_partial from '../assets/images/cards/mcv-nofire-d5-partial.jpeg';
import mcv_nofire_f2_partial from '../assets/images/cards/mcv-nofire-f2-partial.jpeg';
import mcv_fire_partial from '../assets/images/cards/mcv-fire-partial.jpeg';
import mcv_nofire_f2_full from '../assets/images/cards/mcv-nofire-f2-full.jpeg';
import mcv_nofire_d5_full from '../assets/images/cards/mcv-nofire-d5-full.jpeg';

import propylene_fire_full from '../assets/images/cards/propylene-fire-full.jpeg';
import propylene_nofire_d5_partial from '../assets/images/cards/propylene-nofire-d5-partial.jpeg';
import propylene_nofire_f2_partial from '../assets/images/cards/propylene-nofire-f2-partial.jpeg';
import propylene_fire_partial from '../assets/images/cards/propylene-fire-partial.jpeg';
import propylene_nofire_f2_full from '../assets/images/cards/propylene-nofire-f2-full.jpeg';
import propylene_nofire_d5_full from '../assets/images/cards/propylene-nofire-d5-full.jpeg';

import oxygen_full from '../assets/images/cards/oxygen-full.jpeg';
import oxygen_partial from '../assets/images/cards/oxygen-partial.jpeg';

const cardPicturesIndex = {
    'naturalgaz-high-fire-full':naturalgaz_high_fire_full,
    'naturalgaz-high-fire-partial':naturalgaz_high_fire_partial,
    'naturalgaz-high-nofire-full':naturalgaz_high_nofire_full,
    'naturalgaz-high-nofire-partial':naturalgaz_high_nofire_partial,
    'naturalgaz-medium-fire-full':naturalgaz_medium_fire_full,
    'naturalgaz-medium-nofire-full':naturalgaz_medium_nofire_full,
    'naturalgaz-medium-fire-partial':naturalgaz_medium_fire_partial,
    'naturalgaz-medium-nofire-partial':naturalgaz_medium_nofire_partial,

    'ethylene-fire-full':ethylene_fire_full,
    'ethylene-nofire-d5-partial':ethylene_nofire_d5_partial,
    'ethylene-nofire-f2-partial':ethylene_nofire_f2_partial,
    'ethylene-fire-partial':ethylene_fire_partial,
    'ethylene-nofire-f2-full':ethylene_nofire_f2_full,
    'ethylene-nofire-d5-full':ethylene_nofire_d5_full,

    'hcl-fire-partial':hcl_fire_partial,
    'hcl-nofire-partial-novertical':hcl_nofire_novertical_partial,
    'hcl-nofire-partial-vertical':hcl_nofire_vertical_partial,
    'hcl-fire-full':hcl_fire_full,
    'hcl-nofire-full-f2':hcl_nofire_full_f2,
    'hcl-nofire-full-d5':hcl_nofire_full_d5,

    'mcv-fire-full':mcv_fire_full,
    'mcv-nofire-d5-partial':mcv_nofire_d5_partial,
    'mcv-nofire-f2-partial':mcv_nofire_f2_partial,
    'mcv-fire-partial':mcv_fire_partial,
    'mcv-nofire-f2-full':mcv_nofire_f2_full,
    'mcv-nofire-d5-full':mcv_nofire_d5_full,

    'propylene-fire-full':propylene_fire_full,
    'propylene-nofire-d5-partial':propylene_nofire_d5_partial,
    'propylene-nofire-f2-partial':propylene_nofire_f2_partial,
    'propylene-fire-partial':propylene_fire_partial,
    'propylene-nofire-f2-full':propylene_nofire_f2_full,
    'propylene-nofire-d5-full':propylene_nofire_d5_full,

    'oxygen-partial':oxygen_partial,
    'oxygen-full':oxygen_full,
};

export default cardPicturesIndex;