import React from 'react';

const LinkIcon = () => (
    <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.7">
            <path d="M8.55553 4.30556C8.43766 4.30556 8.32461 4.35238 8.24126 4.43573C8.15791 4.51908 8.11109 4.63213 8.11109 4.75V7.41667C8.11109 7.53454 8.06426 7.64759 7.98091 7.73094C7.89756 7.81429 7.78452 7.86111 7.66664 7.86111H2.33333C2.21546 7.86111 2.10241 7.81429 2.01906 7.73094C1.93571 7.64759 1.88889 7.53454 1.88889 7.41667V2.08333C1.88889 1.96546 1.93571 1.85241 2.01906 1.76906C2.10241 1.68571 2.21546 1.63889 2.33333 1.63889H4.99999C5.11786 1.63889 5.23091 1.59206 5.31425 1.50871C5.3976 1.42536 5.44443 1.31232 5.44443 1.19444C5.44443 1.07657 5.3976 0.963524 5.31425 0.880175C5.23091 0.796825 5.11786 0.75 4.99999 0.75H2.33333C1.97971 0.75 1.64057 0.890476 1.39052 1.14052C1.14048 1.39057 1 1.72971 1 2.08333V7.41667C1 7.77029 1.14048 8.10943 1.39052 8.35948C1.64057 8.60952 1.97971 8.75 2.33333 8.75H7.66664C8.02026 8.75 8.3594 8.60952 8.60945 8.35948C8.8595 8.10943 8.99997 7.77029 8.99997 7.41667V4.75C8.99997 4.63213 8.95315 4.51908 8.8698 4.43573C8.78645 4.35238 8.6734 4.30556 8.55553 4.30556Z" strokeWidth="0.421051"/>
            <path d="M6.7777 1.63888H7.47992L4.68439 4.42998C4.64273 4.4713 4.60967 4.52046 4.58711 4.57462C4.56454 4.62877 4.55293 4.68687 4.55293 4.74554C4.55293 4.80421 4.56454 4.8623 4.58711 4.91646C4.60967 4.97062 4.64273 5.01977 4.68439 5.06109C4.72571 5.10275 4.77486 5.13581 4.82902 5.15838C4.88318 5.18094 4.94127 5.19256 4.99994 5.19256C5.05861 5.19256 5.1167 5.18094 5.17086 5.15838C5.22502 5.13581 5.27418 5.10275 5.31549 5.06109L8.11102 2.26999V2.97221C8.11102 3.09009 8.15785 3.20313 8.2412 3.28648C8.32455 3.36983 8.43759 3.41665 8.55546 3.41665C8.67334 3.41665 8.78638 3.36983 8.86973 3.28648C8.95308 3.20313 8.9999 3.09009 8.9999 2.97221V1.19444C8.9999 1.07657 8.95308 0.963523 8.86973 0.880174C8.78638 0.796825 8.67334 0.75 8.55546 0.75H6.7777C6.65983 0.75 6.54679 0.796825 6.46344 0.880174C6.38009 0.963523 6.33326 1.07657 6.33326 1.19444C6.33326 1.31232 6.38009 1.42536 6.46344 1.50871C6.54679 1.59206 6.65983 1.63888 6.7777 1.63888Z" strokeWidth="0.421051"/>
        </g>
    </svg>

);

export default LinkIcon;