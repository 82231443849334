import React from 'react';

// Import component
import Button from './Button';

// Import assets
import WarningIcon from '../../assets/icons/Warning';
import LinkIcon from '../../assets/icons/Link';

const CardComment = ({card, section, cardToggle, addNewCard}) => {

    const redirectToAnOtherCard = () => {
        cardToggle();
        addNewCard(card.redirect + section);
      }

      return (
        <div className="card--answer-content-comments">
          <div className="card--answer-content-comments-container">
            <span className="card--answer-content-comments-icon">
              <WarningIcon />
            </span>
            <p className="card--answer-content-comments-text">
              { !card.redirect &&
                card.comment
              }
              { (card.redirect && card.comment.text) && 
                card.comment.text
              }
            </p>
          </div>

          { (card.redirect && card.comment.text) && 
            <Button buttonStyle="ghost" className="card--answer-content-comments-button" label={card.comment.label} onClick={redirectToAnOtherCard} Icon={LinkIcon} iconRight={true} />
          }

        </div>
      )
}

export default CardComment;