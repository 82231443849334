export const leakQuestion = {
  field: "leak",

  title: "Fuite",
  content: "Quel est le type de fuite ?",

  options: [
    {
      value: "partial",
      label: "<= 10%",
      next: 'pipe'
    },
    {
      value: "full",
      label: "Rupture Guillotine",
      next: 'pipe'
    }
  ]
};

export const leakOxygenQuestion = {
  ...leakQuestion,

  options: [
    {
      value: "partial",
      label: "<= 10%",
      next: false
    },
    {
      value: "full",
      label: "Rupture Guillotine",
      next: 'pipe'
    }
  ]
};

export const leakHclQuestion = {
  ...leakQuestion,

  options: [
    {
      value: "partial",
      label: "<= 10%",
      next: 'stream'
    },
    {
      value: "full",
      label: "Rupture Guillotine",
      next: 'atmospheric_hcl'
    }
  ]
};

export const leakHclFireQuestion = {
  ...leakHclQuestion,

  options: [
    {
      value: "partial",
      label: "<= 10%",
      next: 'pipe'
    },
    {
      value: "full",
      label: "Rupture Guillotine",
      next: 'pipe'
    }
  ]
};
